
@import "../../shared/shared.scss";

.bkg {
    position: relative;

}

.shared--img-fill {
    top: 100px;
}

.frg {
    position: absolute;
    left: 0;
}


.menu {
    position: absolute;
    top: $coreMenuHeight + 25px;
    right: 80px;
    li {
        margin: 0px 0px 35px 0px;
    }
}


.btn {
    position: relative;
    padding: 5px 0px 0px 46px;
    color: $primaryTextColour;
    //font-size: 16px;
    font-size: 20px;
   // @include font-primary;
    font-family: 'Brown';
   // font-family:"ivymode";
    transition: all 0.2s ease-out;
    img {
        position: absolute;
        left: 0;
        top: 0px;
    }
    &:hover {
        opacity: 0.8;
    }
}


    .btn__active {
        @extend .btn;
        color: $highlightTextColour;
    }