
@import "../../shared/shared.scss";


.movies--nav-link__more-movies {
    position: absolute;
    left: 780px;
    top: 326px;
    width: 360px;
    height: 445px;
}


.movies--nav-link__movies {
    position: absolute;
    left: 60px;
    top: 110px;
    width: 170px;
    height: 80px;
}


.movies--button__ird {
    position: absolute;
    left: 100px;
    top: 460px;
    width: 440px;
    height: 300px;
}


.movies--button__construction-timelapse {
    position: absolute;
    left: 1400px;
    top: 460px;
    width: 440px;
    height: 300px;
}


.movies--button__residences {
    position: absolute;
    left: 200px;
    top: 200px;
    width: 500px;
    height: 300px;
}


.movies--button__creation-series-1 {
    position: absolute;
    left: 1160px;
    top: 200px;
    width: 500px;
    height: 300px;
}


.movies--button__creation-series-2 {
    position: absolute;
    left: 200px;
    top: 700px;
    width: 500px;
    height: 300px;
}


.movies--button__creation-series-3 {
    position: absolute;
    left: 1160px;
    top: 700px;
    width: 500px;
    height: 300px;
}


.movies--enlarged {
    z-index: $coreMenuZIndex + 11;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.9);
}


    .movies--enlarged__movie {
        position: absolute;
        left: 320px;
        top: 180px;
    }


    .movies--enlarged__close-btn {
		position: absolute;
		top: 10px;
		right: 10px;
		width: 60px;
		height: 60px;
		background: url(./btn--close.png) no-repeat center center;
		background-size: 40px 40px;
		transition: all 0.3s ease-out;
		&:hover {
			opacity: 0.5;
		}
	}