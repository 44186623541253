
@import "../../shared/shared.scss";


.aerial-map--transform-container {
    position: absolute;
    top: 100px;
    left: 0;
    width: #{( 1920 - 150 )}px;
    height: #{( 980 - 0 )}px;
}

.aerial-map--frg {
    position: absolute;
}


.aerial-map--frg {
    position: absolute;
    left: 0;
    top: 0;
}


.aerial-map--menu {
    position: absolute;
    top: 0px;
    width: 385px;
    height: 980px;
    right: -240px;
    background: $bkgColour;

}


    .aerial-map--menu-options {
        position: absolute;
        top: 0px;
        right: 80px;
        left: 40px;
    }


.aerial-map--title {
    position: relative;
    left: -9px;
    display: block;
    margin: 70px 0px 25px 0px;
    color: $primaryTextColour;
    //font-size: 26px;
    //@include font-secondary;
    font-size: 22px;
    //font-family: 'Domaine Disp';
    font-family:'Brown';
}


.aerial-map--btn {
    position: relative;
    display: block;
    margin: 0px 0px 30px 0px;
    padding: 12px 0px 0px 35px;
    color: $highlightTextColour;
    //font-size: 16px;
    font-size: 17px;
    text-align: left;
    //@include font-primary;
    //font-family:"ivymode";
    font-family: 'Brown';
    img {
        position: absolute;
        left: -18px;
        top: 3px;
    }
    transition: all 0.2s ease-out;
		&:hover {
			color: $primaryTextColour;
		}
}


    .aerial-map--btn__active {
        @extend .aerial-map--btn;
        color: $primaryTextColour;
    }


.aerial-map--zoom-tools {
    z-index: $coreMenuZIndex - 2;
    position: absolute;
    left: 25%;
    font-family: 'Brown';
    top: 780px;
    height: 40px;
    text-align: center;
    transform: translateX(-70%);

    button {
        margin: 0px 10px;
        cursor: pointer;
        transition: all 0.2s ease-out;
		&:hover {
			color: $primaryTextColour;
		}
    }
}

.aerial-map--zoom-tools--title {
    margin: 10px auto 20px;
    @include font-primary;
    text-transform: uppercase;
    color: #fff;
    font-size: 16px;
}