
@import '../../shared/shared.scss';


.amenities--levels {
    position: absolute;
    top: 0px;
    left: 0px;
}


.amenities--ui {
    position: absolute;
    width: 200px;
    right: 80px;
    bottom: 40px;
    ul {
        margin: 0px;
        padding: 0px;
    }
    li {
        position: relative;
        margin: 0;
        margin-bottom: 20px;
        height: 20px;
        padding: 0px;
        list-style-type: none;
        z-index: 0;

        &:before {
            content: '';
            position: absolute;
            top: -4px; left: 0px;
            width: 30px; height: 30px;
            background: #fff;
            border: 2px solid $bkgColour;
            border-radius: 50%;
            z-index: -1;
        }
    }
}


    .amenities--ui__btn {
        min-height: 20px;
        margin: 0;
        padding: 0;
        padding-left: 40px;
        color: $bkgColour;
        font-size: 14px;
        text-transform: uppercase;
        font-weight: normal;
        @include font-primary;
        &:hover {
            font-family: 'Brown';
            font-weight: 800;
            color: $bkgColour;
        }
    }


    .amenities--ui__btn-active {
        @extend .amenities--ui__btn;
        font-family: 'Brown';
        font-weight: 800;
        color: $bkgColour;
        &:hover {
            font-family: 'Brown';
            font-weight: 800;
        }
    }


    .amenities--ui__levels-dragger {
        position: absolute;
        z-index: 3;
        left: 5px;
        top: 1px;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: $bkgColour;
        cursor: pointer;
        transition: all 0.3s ease-out;
        &:hover {
            background: $bkgColour;
            color: red;
        }
    }	


.amenities--hotspots-container {
    position: absolute;
    left: 0px;
    top: 0px;
}


.amenities--hotspot {
    position: absolute;
}


    .amenities--hotspot__btn {
        width: 40px;
        height: 40px;
        background: url(./icon--image.png) no-repeat;
        background-size: 40px 40px;
    }


    .amenities--hotspot__tmb {
        display: none; //show with JS
        position: absolute;

        &, &.bottom-right {
            left: 45px;
            top: 0px;
        }

        &.bottom-left {
            right: 45px;
            left: unset;
            top: 0px;
        }

        &.top-right {
            left: 45px;
            bottom: 0px;
            top: unset;
        }

        &.top-left {
            right: 45px;
            left: unset;
            bottom: 0px;
            top: unset;
        }
    } 